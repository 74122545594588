/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageTitle } from '../../../_metronic/layout/core'
import { Toolbar1 } from '../../../_metronic/layout/components/toolbar/Toolbar1'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import { FC } from 'react'





const DashboardWrapper: FC = () => {
  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userCan = {
    view: user.permissions.includes(`Dashboard View`),
    technical: user.permissions.includes(`Dashboard Tenical`),
    finance: user.permissions.includes(`Dashboard Finance`),
    invoice: user.permissions.includes(`Dashboard Invoice`),
    subscription: user.permissions.includes(`Dashboard Subscription`),
    income: user.permissions.includes(`Dashboard Income`),
    subscriptionList: user.permissions.includes(`Dashboard Subscription List`),
  }

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <Toolbar1 />
      
    </div>
  )
}

export { DashboardWrapper }
